import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/github/workspace/packages/villa-clementine/src/gatsby/gatsby-tpl/mdx-layout-default.jsx";
import { Price } from '../components/Price';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CarouselView = makeShortcode("CarouselView");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ul>
      <li parentName="ul">{`Maison de 5 pièces plus un studio indépendant 144 m², orientée sud et ouest, sur un terrain de 1000 m².`}</li>
      <li parentName="ul">{`Située à La Roquette sur Siagne, nichée sur les collines de la vallée de la Siagne (altitude 80 mètres), elle offre une vue panoramique sur la vallée de la Siagne, la mer et les collines du Tanneron.`}</li>
      <li parentName="ul">{`Salon et salle à manger double exposition, avec grandes baies vitrées Terrasse couverte`}</li>
      <li parentName="ul">{`Cuisine indépendante équipée avec petit cellier.`}</li>
      <li parentName="ul">{`2 chambres avec lit double, salle d’eau privée et toilettes.`}</li>
      <li parentName="ul">{`1 suite parentale avec lit double, salle de bains et toilettes privées, et solarium de 24m².`}</li>
      <li parentName="ul">{`Piscine de 7,6 sur 3.8 m.`}</li>
      <li parentName="ul">{`Studio indépendant avec cuisine équipée, rangements et salle d’eau WC, grande terrasse`}</li>
    </ul>
    <CarouselView images={['exterieur_jardin_01.jpg', 'exterieur_jardin_02.jpg', 'exterieur_succulents.jpg', 'piscine_02.jpg', 'vue_mer.jpg']} mdxType="CarouselView" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      